<template>
  <v-row>
    <v-col
      v-for="(tracker, index) in trackers"
      :key="index"
      cols="6"
      sm="4"
      md="2"
      lg="1"
      class="pa-2 pa-sm-1"
    >
      <v-card
        v-if="tracker.url"
        class="card pa-4"
        color="secondary-lighten"
        :ripple="false"
        block
        flat
        @click="onTrackerClick(tracker)"
      >
        <v-img :src="tracker.imageSrc" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'TrackersList',
    computed: {
      ...mapGetters('settings', ['helpUrls']),
      trackers() {
        return [
          {
            name: 'VOLUUM',
            url: this.helpUrls.voluum,
            imageSrc: require('@/assets/tracking/voluum.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_VOLUUM
          },
          {
            name: 'BeMob',
            url: this.helpUrls.bemob,
            imageSrc: require('@/assets/tracking/bemob.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_BEMOB
          },
          {
            name: 'PeerClick',
            url: this.helpUrls.peerclick,
            imageSrc: require('@/assets/tracking/peerclick.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_PEERCLICK
          },
          {
            name: 'Binom',
            url: this.helpUrls.binom,
            imageSrc: require('@/assets/tracking/binom.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_BINOM
          },
          {
            name: 'AdsBridge',
            url: this.helpUrls.adsbridge,
            imageSrc: require('@/assets/tracking/adsbridge.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_ADSBRIDGE
          },
          {
            name: 'RedTrack',
            url: this.helpUrls.redtrack,
            imageSrc: require('@/assets/tracking/redtrack.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_REDTRACK
          },
          {
            name: 'Keitaro',
            url: this.helpUrls.keitaro,
            imageSrc: require('@/assets/tracking/keitaro.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_KEITARO
          },
          {
            name: 'TriveTracker',
            url: this.helpUrls.thrive_tracker,
            imageSrc: require('@/assets/tracking/trivetracker.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_THRIVE_TRACKER
          },
          {
            name: 'Kintura',
            url: this.helpUrls.kintura,
            imageSrc: require('@/assets/tracking/kintura.svg'),
            gtmEvent: GTM_EVENTS.TRACKING_INTEGRATOR_ACTION_KINTURA
          }
        ].filter(({ url }) => !!url)
      }
    },
    methods: {
      onTrackerClick(tracker) {
        gtmPush({
          event: tracker.gtmEvent
        })
        window.open(tracker.url)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card {
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 4px;

    &:active {
      border: 1px solid var(--v-primary-base) !important;
    }
    &:hover {
      border: 1px solid var(--v-secondary-base) !important;
    }
    &:focus::before {
      opacity: 0;
    }
  }
</style>
